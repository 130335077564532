import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
	Button,
	Dialog,
	makeStyles,
	DialogActions,
	DialogContent,
} from "@material-ui/core";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
	dialogPaper: {
		minWidth: '50vw',
		maxWidth: '50vw',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

const ChamadoModalIframe = ({ modalOpen, onClose, ticketid, chamadoId }) => {
	const [ticketChamadoVinculado, setTicketChamadoVinculado] = useState(null);
	const [url, setUrl] = useState('');
	const { user } = useContext(AuthContext);
	const history = useHistory();

	useEffect(() => {
		if (modalOpen) {
			const fetchChamadoData = async () => {
				try {
					const response = await api.get(`/chamados/${ticketid}`);

					if (response.data.ticket_chamado_vinculado) {
						setTicketChamadoVinculado(response.data.ticket_chamado_vinculado.id);
					} else {
						setTicketChamadoVinculado(null);
					}
					setUrl(response.data.url);
				} catch (err) {
					toastError(err);
				}
			};

			fetchChamadoData();

			const handleMessage = (event) => {
				if (event.data.sucesso) {
					api.put(`/tickets/${ticketid}`, {});
					// onClose();
				}
			};

			window.addEventListener("message", handleMessage);

			return () => {
				window.removeEventListener("message", handleMessage);
			};
		}
	}, [modalOpen, ticketid, onClose]);

	const handleDeleteVinculoChamado = async (ticketChamadoVinculado) => {
		try {
			const retorno = await api.delete(`/chamados/${ticketChamadoVinculado}`);

			if (retorno.data.sucesso === true) {
				toast.success((retorno.data.msg || "Vínculo removido com sucesso"));

				setTicketChamadoVinculado(null);

				api.put(`/tickets/${ticketid}`, {});
				onClose();
			} else {
				return;
			}
		} catch (err) {
			toast.error(err);
		}
	};

	const handleClose = () => {
		onClose();
		if (!ticketChamadoVinculado) {

		}
	};

	return (
		<div>
			{!chamadoId && (
				<Dialog open={modalOpen} onClose={onClose} fullWidth maxWidth="lg">
					<DialogContent>
						<iframe
							src={url + "lf/sistema/gerenciamentochamados?aberto_iframe=S&id_ticket=" + ticketid}
							width="100%"
							height="800px"
							style={{ border: 'none' }}
							title="Novo Chamado"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={onClose}
							color="primary"
							variant="outlined"
						>Fechar</Button>
					</DialogActions>
				</Dialog>
			)}
			{chamadoId && (
				<Dialog open={modalOpen} onClose={onClose} fullWidth maxWidth="lg">
					<DialogContent>
						<iframe
							src={url + "lf/sistema/gerenciamentochamados?aberto_iframe=S&id_chamado=" + chamadoId}
							width="100%"
							height="800px"
							style={{ border: 'none' }}
							title="Editar Chamado"
						/>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							onClick={() => handleDeleteVinculoChamado(chamadoId)}
							color="secondary"
							style={{ display: chamadoId ? 'block' : 'none' }}
						>Desvincular</Button>
						<Button
							onClick={onClose}
							color="primary"
							variant="outlined"
						>Fechar</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default ChamadoModalIframe;