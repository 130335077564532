import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IconButton } from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";

import LoggedInLayout from "../layout";
import LayoutTicketCrm from "../layout/layoutCrm";

import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import TicketCrm from "../pages/TicketCrm/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import InicializarWhatsapp from "../pages/Login/Inicializarwhatsapp";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";

import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";

const Routes = ({ toggleDarkMode, darkMode }) => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <WhatsAppsProvider>
          <IconButton
            onClick={toggleDarkMode}
            color="inherit"
            style={{
              position: "fixed",
              bottom: 16,
              left: 16,
              zIndex: 10000,
              backgroundColor: "inherit",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <Switch>
            {/* Rotas sem layout */}
            <Route exact path="/inicializarwhatsapp" component={InicializarWhatsapp} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />

            {/* Rotas com LoggedInLayout */}
            <Route
              exact
              path="/"
              component={Tickets}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
            <Route
              exact
              path="/dashboard"
              component={Dashboard}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
            <Route
              exact
              path="/tickets/:ticketId?"
              component={Tickets}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
            <Route
              exact
              path="/connections"
              component={Connections}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
            <Route
              exact
              path="/contacts"
              component={Contacts}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
            <Route
              exact
              path="/users"
              component={Users}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
            <Route
              exact
              path="/quickAnswers"
              component={QuickAnswers}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
            <Route
              exact
              path="/Settings"
              component={Settings}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
            <Route
              exact
              path="/Queues"
              component={Queues}
              layout={LoggedInLayout}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />

            {/* Rotas com LayoutTicketCrm */}
            <Route
              exact
              path="/ticketcrm/:ticketId?"
              component={TicketCrm}
              layout={LayoutTicketCrm}
              isPrivate
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
            />
          </Switch>
        </WhatsAppsProvider>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
