import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import { Tooltip } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import TicketListOptionsMenu from "../TicketListOptionsMenu";
import { useTickets } from "../../context/Ticket/TicketContext";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},
	pendingTicket: {
		cursor: "unset",
	},
	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},
	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},
	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	lastMessageTime: {
		justifySelf: "flex-end",
	},
	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},
	contactLastMessage: {
		paddingRight: 20,
	},
	newMessagesCount: {
		alignSelf: "center",
		marginRight: 2,
		marginLeft: "auto",
	},
	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},
	acceptButton: {
		position: "absolute",
		left: "50%",
	},
	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	div_tags: {
		position: "absolute",
		display: "flex",
		justifyContent: "flex-end",
		gap: 5,
		right: 5,
		top: '32px',
	},
	userTag: {
		bottom: 5,
		background: "#2576D2",
		color: "#ffffff",
		border: "1px solid #2576D2",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},
	crmTag: {
		bottom: 5,
		background: "#f50057",
		color: "#ffffff",
		border: "1px solid #f50057",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},
	chamadoTag: {
		bottom: 5,
		background: "#0fbb58",
		color: "#ffffff",
		border: "1px solid #0fbb58",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},
	optionsMenu: {
		position: "absolute",
		zIndex: 1000,
	},
}));

const TicketListItem = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const [userName, setUserName] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
	const ticketListOptionsMenuOpen = Boolean(anchorEl);
	const [limiteTicket, setLimiteTicket] = useState(null);
	const { openCount } = useState(null);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchUserName = async () => {
			if (ticket.userId) {
				try {
					const response = await api.get(`/users/${ticket.userId}`);
					if (isMounted.current) {
						setUserName(response.data.name);
					}
				} catch (err) {
					toastError(err);
				}
			}
		};

		fetchUserName();
	}, [ticket.userId]);

	useEffect(() => {
		const fetchLimiteTicket = async () => {
			try {
				const response = await api.get(`/paramempresa/`);
				setLimiteTicket(response.data.parametros.nr_limite_tickets);
			} catch (err) {
				toastError(err);
			}
		}

		fetchLimiteTicket();
	}, [user.id]);

	const idgrupoempresa = user.idgrupoempresa;

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
				idgrupoempresa: idgrupoempresa,
			});
			if (isMounted.current) {
				setLoading(false);
			}
			history.push(`/tickets/${id}`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	const handleSelectTicket = id => {
		history.push(`/tickets/${id}`);
	};

	const handleOpenTicketListOptionsMenu = (e) => {
		e.preventDefault();
		setAnchorEl(e.currentTarget);
		setMenuPosition({ top: e.clientY, left: e.clientX });
	};

	const handleCloseTicketListOptionsMenu = () => {
		setAnchorEl(null);
	};

	let admin = false;

	if (user.profile === "admin") {
		admin = true
	}

	return (
		<React.Fragment>
			<ListItem
				dense
				button
				onClick={() => {
					if (ticket.status !== "pending") {
						handleSelectTicket(ticket.id);
					}
				}}
				onContextMenu={admin ? (e) => handleOpenTicketListOptionsMenu(e) : ""}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}
			>
				<Tooltip
					arrow
					placement="right"
					title={ticket.queue?.name || "Sem fila"}
				>
					<span
						style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					></span>
				</Tooltip>
				<ListItemAvatar>
					<Avatar src={ticket?.contact?.profilePicUrl} />
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{ticket.contact.name}
							</Typography>
							{ticket.status === "closed" && (
								<Badge
									className={classes.closedBadge}
									badgeContent={"closed"}
									color="primary"
								/>
							)}
							{ticket.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{ticket.status === "pending" ? (
										isSameDay(parseISO(ticket.createdAt), new Date()) ? (
											format(parseISO(ticket.createdAt), "HH:mm")
										) : (
											format(parseISO(ticket.createdAt), "dd/MM/yyyy")
										)
									) : (
										isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
											format(parseISO(ticket.updatedAt), "HH:mm")
										) : (
											format(parseISO(ticket.updatedAt), "dd/MM/yyyy")
										)
									)}
								</Typography>
							)}
							<div className={classes.div_tags}>
								{Array.isArray(ticket.gerenciarchamado) && ticket.gerenciarchamado.length > 1 ? (
									<div className={classes.chamadoTag} title={i18n.t("Chamado")}>
										{`${ticket.gerenciarchamado.length} chamados`}
									</div>
								) : (
									ticket.gerenciarchamado && ticket.gerenciarchamado.length === 1 && (
										<div className={classes.chamadoTag} title={i18n.t("Chamado")}>
											{'#' + ticket.gerenciarchamado[0].id}
										</div>
									)
								)}
								{ticket.crm && (
									<div className={classes.crmTag} title={i18n.t("Card CRM")}>
										{'#' + ticket.crm.id}
									</div>
								)}
								{ticket.userId && (
									<div className={classes.userTag} title={i18n.t("ticketsList.connectionUser")}>
										{userName}
									</div>
								)}
								{!ticket.userId && (
									<div className={classes.userTag} title={i18n.t("ticketsList.connectionTitle")}>
										{"PENDENTE"}
									</div>
								)}
							</div>
						</span>
					}
					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{ticket.lastMessage ? (
									<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
								) : (
									<br />
								)}
							</Typography>

							<Badge
								className={classes.newMessagesCount}
								badgeContent={ticket.unreadMessages}
								classes={{
									badge: classes.badgeStyle,
								}}
							/>
						</span>
					}
				/>
				{ticket.status === "pending" && (
					<ButtonWithSpinner
						disabled={limiteTicket && openCount >= limiteTicket}
						color="primary"
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={() => handleAcepptTicket(ticket.id)}
					>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>
				)}
			</ListItem>
			<TicketListOptionsMenu
				ticket={ticket}
				anchorEl={anchorEl}
				menuOpen={ticketListOptionsMenuOpen}
				handleClose={handleCloseTicketListOptionsMenu}
				menuPosition={menuPosition}
				className={classes.optionsMenu}
			/>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default TicketListItem;
